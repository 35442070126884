import React from "react";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';

class NewsSingle extends React.Component {

  // State will apply to the posts object which is set to loading by default
  state = {
    newslist: [],
    isLoading: true,
    errors: null
  };


  // Now we're going to make a request for data using axios
  getPosts() {
      // let newsName = this.props.location;
     let newsName = window.location.pathname;   


    let lastChar = newsName.substr(-1); // Selects the last character
    if (lastChar !== '/') {         // If the last character is not a slash
       newsName = newsName + '/';            // Append a slash to it.
    }


    axios
      // This is where the data is hosted
      .get(`${apiUrl}/${newsName}?api=true`, {
    headers: {
        // 'Content-Type': 'application/json'
    },

    })  
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          newslist: response.data,
          isLoading: false
        });
                      
      })
      // If we catch any errors connecting, let's update accordingly
            .catch(error => {
                console.log(error);
                    window.location.href = '/not-found';

                this.setState({ error, isLoading: false } )
            });
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {

    this.getPosts();
   



  }
  // Putting that data to use
  render() {
    setTimeout(() => {
            if (window.instgrm) {
                window.instgrm.Embeds.process()
            }
    }, 2000);
    const { isLoading, newslist } = this.state;
     return (
      <React.Fragment>
    <div className="news-single">
        <div>
          {!isLoading ? (
            newslist.map(news => {

              const { id, title, news_image, news_date_added, news_full_description } = news;
              document.title = title + " - Radio Kampus 97,1FM #SAMESZTOSY";
              let news_date = new Date(news_date_added).toLocaleDateString('pl-PL', {
                  weekday: 'long',
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
              })


              return (
                <div className="news-single-element" key={id}>
                  <h2>{title}</h2>
                  <span className="news--datetime">{news_date}</span>
                  <div className="news--image"><img src={news_image} alt="" /></div>
                  <div className="news--full-description" dangerouslySetInnerHTML={ { __html: news_full_description }} />
                </div>
              );
            })
          ) : (
            <p>Wczytywanie...</p>
          )}
        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewsSingle;