import React from "react";
import {
  Link
} from "react-router-dom";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';

class PlayerCurrentProgram extends React.Component {
  // State will apply to the posts object which is set to loading by default
  state = {
    programs: [],
    isLoading: true,
    errors: null,
  };





  // Now we're going to make a request for data using axios
  getPosts() {




        let schedule_query = new Date().toLocaleDateString('pl-PL', {
            weekday: 'long'
        })
        let schedule_query_done = schedule_query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\u0142/g, "l");





      let program_category_query = schedule_query_done;
    

  
    axios
      // This is where the data is hosted
      .get(`${apiUrl}/ramowka/${program_category_query}/?api=true`, {
}) 
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          programs: response.data,
          isLoading: false
        });
                      
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false }));
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {
this.getPosts();
this.interval = setInterval(() => this.getPosts(), 60000);
  }


componentDidUpdate(prevProps, prevState) {
  // only update chart if the data has changed
  if (prevProps.path !== this.props.path) {
    this.getPosts();
  }
}




  // Putting that data to use
  render() {
    const { isLoading, programs } = this.state;



    // GENERATE DYNAMIC SLIDING IF TEXT OVERFLOWS PLAYLIST AREA


    setTimeout(() => {
        
        let elementUp = document.querySelector('.played-audition .played-song');
        let element = document.querySelector('.played-audition .rotation-song');
        if (element !== null) {
            var overflowX = elementUp.offsetWidth < (element.scrollWidth - 10);
            console.log("OFFSET PROG BOX: " + elementUp.offsetWidth);
            console.log("OFFSET PROG TITLE: " + element.scrollWidth);
            console.log("WYNIK PROG: " + overflowX);
            if (overflowX === true) {
                element.classList.add("active");
                element.classList.remove("enough");
            } else {
                element.classList.remove("active");
                element.classList.add("enough");
            }

        }
         }, 200);



    return (
      <React.Fragment>
                <div className="played-audition">
                  <div className="played-title"><span>AUDYCJA:</span></div>
          {!isLoading ? (
            programs.map(program => {
              const { id, program_title, program_url, program_time_start, program_time_end } = program;

    let startTime = program_time_start;
    let endTime = program_time_end;
    let currentDate = new Date();   
    let startDate = new Date(currentDate.getTime());
    startDate.setHours(startTime.split(":")[0]);
    startDate.setMinutes(startTime.split(":")[1]);
    let endDate = new Date(currentDate.getTime());
    endDate.setHours(endTime.split(":")[0]);
    endDate.setMinutes(endTime.split(":")[1]);
    let valid = startDate <= currentDate && endDate >= currentDate;
              
              return (
                (valid ? ( 
                  <div className="played-song" key={id}><span className="rotation-song"><Link to={program_url}><span className="schedule--title">{program_title}</span></Link></span>                 
                </div>) : ('') )
              );
            })
          ) : (
            <div className="played-song">Wczytywanie...</div>
          )}
        <div className="audition-playlist-url"><Link to="/playlista/">PLAYLISTA</Link></div>
        </div>
      </React.Fragment>
    );
  }
}

export default PlayerCurrentProgram;