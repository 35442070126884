// export const apiUrl =
// process.env.NODE_ENV === "development" ? "http://kampus.webdev-beta.pl" : "https://api.radiokampus.fm";
export const apiUrl =
    process.env.NODE_ENV === "development" ? "https://kampus.webdev-beta.pl" : "https://kampus.webdev-beta.pl";

// API OTHER - radiokampus.fm/api/:

// DayPlaylistElement
// PlayerCurrentSong
// PlayerPreviousSong
// PlayerPreviousSong__Spotify-API
