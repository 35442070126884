import React from "react";
import {
  Link
} from "react-router-dom";
import Pagination from './Pagination';
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';

class ProgramListElement extends React.Component {

    state = {
      programs: [],
      isLoading: true,
      errors: null,
    };

    getPosts() {
        let page_number;
        let page_category_query;
        let page_base_url = 'programy';



        // PAGE CATEGORY RULES
            console.log("TYP STRONY: " + page_base_url);
            let page_category_url_split = window.location.pathname.split("/");
                // console.log(page_category_url_split[2]);
            let page_category_url_pop = page_category_url_split.pop();
                // console.log(page_category_url_pop);
                // let page_category_url_start = window.location.pathname.split("/").pop();
            if( !page_category_url_pop ) {
                page_category_url_pop = page_category_url_split.pop();
            }

            var lastChar = window.location.pathname.substr(-1); // Selects the last character
                if (lastChar !== '/') {         // If the last character is not a slash

                }

            if ((window.location.pathname === '/'+page_base_url+'') || (window.location.pathname === '/'+page_base_url+'/')) {
                // console.log("TAK");
                page_category_query = '&toplevel=true';
            } else if (document.location.pathname.indexOf('/'+page_base_url+'/page') === 0) {
                page_category_query = '&toplevel=true';
            } else { 
                // console.log("NIE");
                let page_category_url;
                if (window.location.pathname.indexOf('/page') >= 0) {
                    page_category_url = window.location.pathname.split("/")[2];
                } else {
                    page_category_url = page_category_url_pop.replace(/\/$/, '');
                }
                page_category_query = page_category_url;
                console.log("KATEGORIA:" + page_category_url); 
            } 

            // Check page URL
            if( window.location.pathname.indexOf('/page') >= 0) {
                page_number = window.location.pathname.split("page")[1];
                // console.log("Wybrana strona: " + page_number);
            } else {
                page_number = '1';
                // console.log("Strona poczatkowa")
            }
        // END PAGE CATEGORY RULES



        axios
            .get(`${apiUrl}/${page_base_url}/page${page_number}?api=true&parent=${page_category_query}`, {
                headers: {
                    // 'Content-Type': 'application/json'
                },

            }) 
            // Once we get a response and store data, let's change the loading state
            .then(response => {
                this.setState({
                    programs: response.data,
                    isLoading: false
                });               
            })
            // If we catch any errors connecting, let's update accordingly
            .catch(error => {
                console.log(error);
                    window.location.href = '/not-found';

                this.setState({ error, isLoading: false } )
            });
    }

    componentDidMount() {
        this.getPosts();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.path !== this.props.path) {

            this.setState({
                isLoading: true,
            });
            this.getPosts();

        }
    }

    render() {
        let page_number;
        const { isLoading, programs } = this.state;

        let page_path = window.location.pathname.split('/page')[0];
            // console.log(page_path);
            // DUPLICATED PAGE NUMBER
        if( window.location.pathname.indexOf('/page') >= 0) {
            page_number = window.location.pathname.split('/page')[1];
            console.log("Wybrana strona: " + page_number);
        } else {
            page_number = '1';
            // console.log("Strona poczatkowa")
        }
            // END DUPLICATION

        if (programs !== null) {
            if (programs[0] !== undefined ) {
                //    PAGINATION
                //    console.log(programs);
                //    console.log(programs[0].pages_total)
                let number_of_pages = programs[0].pages_total;
                const page_numbers = [];



                  
                ////////////////////////////////////////////////////////////////////////////////
                Pagination( number_of_pages, page_numbers, page_number, page_path );
                ////////////////////////////////////////////////////////////////////////////////




                return (
                    <React.Fragment>
                        <ul className="program-list">
                        {!isLoading ? (
                            (programs !== null) ? ( (programs.map(program => {

                              const { id, title, url, programy_kategorie, program_short_description } = program;
                              return (
                                <li className="program-item" key={id}>
                                  <h3 className="program--title"><Link to={url}>{title}</Link></h3>
                                  <span className="program-short-description"><span className="program-short-description--container">{program_short_description}<br/><br/><span className="program--tags">#{programy_kategorie}</span></span></span>
                                  
                                </li>
                              )
                            }))  ) : (<p>Nie znaleziono programów.</p>)
                        ) : (
                            <p>Wczytywanie...</p>
                        )}
                        </ul>
                        <ul className="pagination">
                            {page_numbers}
                        </ul>
                    </React.Fragment>
                );

            } else {
                return (
                    <React.Fragment>
                        <ul className="program-list">
                        {!isLoading ? (
                            (programs !== null) ? ( (programs.map(program => {

                                const { id, title, url, programy_kategorie, program_short_description } = program;
                                return (
                                    <li className="program-item" key={id}>
                                        <h3 className="program--title"><Link to={url}>{title}</Link></h3>
                                        <span className="program-short-description"><span className="program-short-description--container">{program_short_description}<br/><br/><span className="program--tags">#{programy_kategorie}</span></span></span>
                                    </li>
                                )

                            }))  ) : (<p>Nie znaleziono programów.</p>)
                          ) : (
                              <p>Wczytywanie...</p>
                          )}
                        </ul>
                    </React.Fragment>
                );
            }
        } else {
            return <p>Nie znaleziono programów.</p>
        }
    }
}


export default ProgramListElement;