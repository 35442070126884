import React from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import {apiUrl} from './../Config/ApiSettings';


class PlayerTopSongs extends React.Component {

  state = {
    songs: [],
    firstsong: [],
    isLoading: true,
    errors: null,
  };


  getSongs() {
	    axios
	      .get(`${apiUrl}/playlista/`, {
			}) 

	      .then(response => {
	        this.setState({
	          songs: response.data,
	          isLoading: false
	        });                 
	      })
	      .catch(error => this.setState({ error, isLoading: false }));
  }


  getCurrent() {
	    axios
	      .get(`${apiUrl}/playlista/utwory/`, {
				}) 

	      .then(response => {
	        this.setState({
	          firstsong: response.data,
	          isLoading: false
	        });        
	      })
	      .catch(error => this.setState({ error, isLoading: false }));
  }


  componentDidMount() {
			this.getCurrent();
			this.getSongs();
			this.interval = setInterval(() => {
				  this.getCurrent();
				  this.getSongs();}
			, 30000);
  }


  componentWillUnmount() {
    	clearInterval(this.interval);
  }

	componentDidUpdate(prevProps, prevState) {
		  if (prevProps.path !== this.props.path) {
				  this.getCurrent();
				  this.getSongs();
		  }
	}


  render() {
    	const { isLoading, songs, firstsong } = this.state;

  		//  console.log(songs[1]);

  		
    	if (songs === null) {
	        let songs = [{
	          song_id : "1",
	          artist : "Same",
	          title : "sztosy!",
	        }, {
	          song_id : "1",
	          artist : "Same",
	          title : "sztosy!",
	        }]

        	let secondToLastSong = songs[1];
			    let played_now = document.querySelector(".playlist-area .played-now");
			    let audition_now = document.querySelector(".playlist-area .played-audition");
	        if ((played_now !== null) && (audition_now !== null)) {
	        	played_now.classList.add("only");
	        audition_now.classList.add("only");
	      }


			    return (
				      <React.Fragment>
					        <div className="played-now">
					            <div className="played-title"><span>TERAZ:</span></div>
								          {!isLoading ? (
								            	firstsong.map(song => {
								              		const { artist, title } = song;

										              if (artist === 'Radio Kampus 97,1 FM') {
												              return (
												                  <div className="played-song" key={artist}>
												                  		<Link to="/playlista"><span className="song-title">{title}</span></Link>               
												                	</div> 
												              )
										            	} else {
												              return (
												                  <div className="played-song" key={artist}><span className="rotation-song">
										                  				<Link to="/playlista"><span className="artist">{artist}</span> - <span className="song-title">{title}</span></Link>
										                  		</span></div> 
										                  )              
										            	}
								            	})
								          ) : (
								            	<div className="played-song">
								            			<span className="song-title">Wczytywanie...</span>
						            			</div>
								          )}
					        </div>
									<div className="played-before">	
						          {!isLoading ? (
				                  <div className="played-song" key={secondToLastSong.song_id}><span className="rotation-song">
				                  		<span className="artist"></span> <span className="song-title"></span>
		                  		</span></div>             
						          ) : (
						            	<div className="played-song">
						            			<span className="song-title">Wczytywanie...</span>
				            			</div>
						          )}
					        </div>
				      </React.Fragment>
			    );
 			}
  		else {
					let secondToLastSong = songs[1];
					let firstSongNow = firstsong[0];
					// console.log(secondToLastSong);
					if (firstSongNow) {
							// console.log(firstSongNow.artist);
							if (firstSongNow.artist === 'Radio Kampus 97,1 FM') {secondToLastSong = songs[0] } else { secondToLastSong = songs[1] }
					}
 					//   console.log(testy);
    			if (secondToLastSong !== undefined) {

							console.log("Last song started:" + secondToLastSong.start_time);
					    let startTime = secondToLastSong.start_time;
					    let currentDate = new Date();   
					    let startDate = new Date(currentDate.getTime());
					    startDate.setHours(startTime.split(":")[0]);
					    startDate.setMinutes(startTime.split(":")[1]);
					    let endDate = new Date(startDate.getTime() + 15*60000);

					    let valid = endDate > currentDate;
					    // console.log( currentDate );
					    // console.log( endDate );
					    // console.log( startDate );
					    // console.log( endDate - startDate );
					    // console.log( valid );

					    let played_now = document.querySelector(".playlist-area .played-now");
					    let audition_now = document.querySelector(".playlist-area .played-audition");

					    if (played_now !== null) {
							    if (!valid) {
							        played_now.classList.add("only");
							        audition_now.classList.add("only");
							    } else {
							        played_now.classList.remove("only");
							        audition_now.classList.remove("only");
							    }
							}


    					// GENERATE DYNAMIC SLIDING IF TEXT OVERFLOWS PLAYLIST AREA


					    setTimeout(() => {
					        
					        let elementUp = document.querySelector('.played-before .played-song');
					        let element = document.querySelector('.played-before .rotation-song');
					        if (element !== null) {
					            var overflowX = elementUp.offsetWidth < (element.scrollWidth + 10);
					            console.log("OFFSET PREV BOX: " + elementUp.offsetWidth);
					            console.log("OFFSET PREV TITLE: " + element.scrollWidth);
					            console.log("WYNIK PREV: " + overflowX);
					            if (overflowX === true) {
					                element.classList.add("active");
					                element.classList.remove("enough");
					            } else {
					                element.classList.remove("active");
					                element.classList.add("enough");
					            }

					        }

						      let elementOneUp = document.querySelector('.played-now .played-song');
						      let elementOne = document.querySelector('.played-now .rotation-song');
						      if (elementOne !== null) {
						          var overflowOneX = elementOneUp.offsetWidth < (elementOne.scrollWidth + 10);
						          console.log("OFFSET NOW BOX: " + elementOneUp.offsetWidth);
						          console.log("OFFSET NOW TITLE: " + elementOne.scrollWidth);
						          console.log("WYNIK NOW: " + overflowOneX);
						          if (overflowOneX === true) {
						              elementOne.classList.add("active");
						              elementOne.classList.remove("enough");
						          } else {
						              elementOne.classList.remove("active");
						              elementOne.classList.add("enough");
						          }

						      }
							}, 200);

					    return (
					      <React.Fragment>
						        <div className="played-now">
						            <div className="played-title"><span>TERAZ:</span></div>
						          {!isLoading ? (
						            firstsong.map(song => {
						              const { artist, title } = song;

						              if (artist === 'Radio Kampus 97,1 FM') {
						              return (
						                  <div className="played-song" key={artist}><Link to="/playlista"><span className="song-title">{title}</span></Link>               
						                </div> )
						            } else {
						              return (
						                  <div className="played-song" key={artist}><span className="rotation-song"><Link to="/playlista"><span className="artist">{artist}</span> - <span className="song-title">{title}</span></Link></span>                
						                </div> )              
						            }
						            })
						          ) : (
						            <div className="played-song"><span className="song-title">Wczytywanie...</span></div>
						          )}
						        </div>
										<div className="played-before">	
							          {!isLoading ? (
							              (valid ? (  <React.Fragment><div className="played-title"><span>BYŁO:</span></div> <div className="played-song" key={secondToLastSong.song_id}><span className="rotation-song"><Link to="/playlista"><span className="artist">{secondToLastSong.artist}</span> - <span className="song-title">{secondToLastSong.title}</span> </Link> </span>              
							                </div></React.Fragment>        ) : ( <span></span> ) ) 

							          ) : (
							            <div className="played-song"><span className="song-title">Wczytywanie...</span></div>
							          )}
						        </div>
					      </React.Fragment>
					    );
			    } else {
			      return null
			    }
  		}
  }
}

export default PlayerTopSongs;