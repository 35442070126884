import React from "react";
import Breadcrumbs from '.././Components/Breadcrumbs';
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';

class People extends React.Component {

  // State will apply to the posts object which is set to loading by default
  state = {
    people: [],
    isLoading: true,
    errors: null
  };


  // Now we're going to make a request for data using axios
  getPosts() {
      // let peopleName = this.props.location;
     let peopleName = window.location.pathname; 

    let lastChar = peopleName.substr(-1); // Selects the last character
    if (lastChar !== '/') {         // If the last character is not a slash
       peopleName = peopleName + '/';            // Append a slash to it.
    }


    axios
      // This is where the data is hosted
      .get(`${apiUrl}/${peopleName}?api=true`, {
    headers: {
        // 'Content-Type': 'application/json'
    },

    }) 
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          people: response.data,
          isLoading: false
        });
                      
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false }));
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {

    this.getPosts();
  }
  // Putting that data to use
  render() {

    const { isLoading, people } = this.state;
     return (
        <div className="people-section">
                  <Breadcrumbs />
                <div className="content-header with-filter">
					<h2 className="subpage-title">Ludzie</h2>
				</div>      
        <div className="people-list-container">

	        <div className="people-single-list">
	          {!isLoading ? (
	            people.map(people => {

	              const { id, person_name, description } = people;
	              document.title = "Ludzie - Radio Kampus 97,1FM #SAMESZTOSY";
	              return (
	                <div key={id} className="people-single-element">
	                  <h3>{person_name}</h3>
	                  <div className="people-description">
	                    <div dangerouslySetInnerHTML={ { __html: description }} />
	                  </div>
	                  <hr />
	                </div>
	              );
	            })
	          ) : (
	            <p>Wczytywanie...</p>
	          )}
	       	</div>
         </div>
       </div>

    );
  }
}

export default People;