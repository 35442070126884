import React from "react";
import {
  Link,
} from "react-router-dom";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';

class NewsList extends React.Component {
  // State will apply to the posts object which is set to loading by default
  state = {
    newslist: [],
    isLoading: true,
    errors: null
  };
  // Now we're going to make a request for data using axios
  getPosts() {
    axios
      // This is where the data is hosted
      .get(`${apiUrl}/aktualnosci/page1?api=true&limit=6&sort=-created|-priority`, {
    headers: {
        // 'Content-Type': 'application/json'
    },

    }) 
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          newslist: response.data,
          isLoading: false
        });
                      
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false }));
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getPosts();
  }
  // Putting that data to use
  render() {
    const { isLoading, newslist } = this.state;

    return (
      <React.Fragment>
		<div className="news-section">
			<div className="dynamic-boxes">
				<div className="box-1"> </div>
				<div className="box-2"> </div>
				<div className="box-3"> </div>
			</div>
			<h2 className="">Aktualności</h2>


			<div className="news-list">
	          {!isLoading ? (
	            newslist.map(news => {

	              const { id, title, news_image, url, news_date_added, news_short_description } = news;

let news_date = new Date(news_date_added).toLocaleDateString('pl-PL', {
    weekday: 'long',
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
})


	              return (
	                <div className="news-item" key={id}>
	                  <Link to={url}><img className="news--image" src={news_image} alt="" /></Link>
	                  <h3 className="news--title"><Link to={url}>{title}</Link></h3>
						<div className="news-metadata">
							<span className="news--datetime">{news_date}</span>
							<div className="news--description">
								<p>{news_short_description}</p>
							</div>
						</div>
	                </div>
	              );
	            })
	          ) : (
	            <p>Wczytywanie...</p>
          )}
          </div>
          {!isLoading ? (
          <div className="news-more-button">
              <Link to="/aktualnosci" className="news-more--url">Więcej</Link>
          </div>
             ) : (<span></span>)}
        </div>
      </React.Fragment>
    );
  }
}

export default NewsList;